<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Add Voter</div>
  </div>
  <div class="layout-content-section">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="aadhar-details-tab" data-bs-toggle="tab" data-bs-target="#aadharvoterdetail"
          type="button" role="tab" aria-controls="aadhar-tab-details" aria-selected="false"
          @click="redirectaddusingaadharurl()">
          Add using Aadhar
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="votercard-details-tab" data-bs-toggle="tab"
          data-bs-target="#voterusingvoterdetail" type="button" role="tab" aria-controls="voter-tab-details"
          aria-selected="true">
          Using Voter Id
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="manual-entry-tab" data-bs-toggle="tab" data-bs-target="#voterreport" type="button"
          role="tab" aria-controls="customer-details" aria-selected="false" @click="redirectaddusingmanualurl()">
          Add Manually
        </button>
      </li>
    </ul>
    <div class="voter-detail-box-outer">
      <div class="row">
        <div class="col-12">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" id="aadharvoterdetail" role="tabpanel" aria-labelledby="aadhar-details-tab">
              <div>Using Aadhar ID</div>
            </div>
            <div class="tab-pane fade show active" id="voterusingvoterdetail" role="tabpanel"
              aria-labelledby="votercard-details-tab">
              <div class="company-section-outer" style="height: calc(100vh - 196px); padding: 16px 16px 0;">
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                      <div class="input-group custom-input-group">
                        <div class="col-lg-4 col-md-4 col-12">
                          <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true" label="label"
                            placeholder="Select" disabled class="multiselect-custom custom-title-multiselect"
                            :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                            :canDeselect="false" />
                        </div>
                        <div class="col-lg-8 col-md-8 col-12">
                          <input type="text" v-model="votermobileno" id="searchmobInput" class="form-control"
                            placeholder="Enter Mobile No." :disabled="epicdivdisplayflag" maxlength="10"
                            @keypress="mobileSearchFormat" @paste="onPasteMobile" autocomplete="off" />
                        </div>
                        <div class="custom-error" v-if="v$.votermobileno.$error">
                          {{ v$.votermobileno.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Voter Id<span class="text-danger">*</span></label>
                      <input type="text" v-model="votercardnumber" class="form-control text-uppercase"
                        id="formLastNameInput" :disabled="epicdivdisplayflag" placeholder="Enter Voter Id"
                        autocomplete="off" maxlength="10" />
                      <span class="custom-error" v-if="voteriderr">
                        {{ voteriderr }}
                      </span>
                      <span class="custom-error" v-if="invalidVoterMsg">
                        {{ invalidVoterMsg }}
                      </span>
                      <div class="custom-error" v-if="v$.votercardnumber.$error">
                        {{ v$.votercardnumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="
                              col-lg-2 col-md-2 col-12">
                    <div class="custom-form-group" style="margin-top: 26px;">
                      <label class="form-label"></label>
                      <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '110px' }"
                        @click="getVoterDetail()" :disabled="errStatus || votercardnumber.length != 10 || epicdivdisplayflag
                          ">
                        <span v-if="!showloader">Search</span>
                        <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="epicdivdisplayflag == 1">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-10 col-md-10 col-12">
                        <div class="voter-already-user-status">
                          {{ this.votermessage }}
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12 text-end">
                        <button type="button" class="clear-addvoter-btn btn btn-primary" @click="reloadepictab()">
                          Clear All
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="epicdivdisplaydetailflag">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div style="position: relative; min-height: 268px;">
                      <div v-if="!showloadervotermodal">
                        <div class="col-lg-12 col-md-12 col-12" v-if="epicvoterDetails != ''">
                          <div class="voter-exit-box-outer mb-3">
                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Voter Number</label>
                                  <div class="from-label-value text-uppercase">
                                    {{
                                      epicvoterDetails.ak35 ? epicvoterDetails.ak35 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Name</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ak5 ? epicvoterDetails.ak5 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12 text-end" v-if="updatebtnstatus">
                                <button type="button" @click="confirmModalOpen(epicvoterDetails)"
                                  class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                  :style="{ width: '70px' }">
                                  <span v-if="!showupdateloader">Update</span>
                                  <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                    v-if="showupdateloader">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Part Name</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ab4 ? epicvoterDetails.ab4 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Part Number</label>
                                  <div class="from-label-value">
                                    {{
                                      epicvoterDetails.ak85 ? epicvoterDetails.ak85 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Polling Station</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ab7 ? epicvoterDetails.ab7 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Age</label>
                                  <div class="from-label-value">
                                    {{
                                      epicvoterDetails.ak19 ? epicvoterDetails.ak19 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Gender</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ak22
                                      ? epicvoterDetails.ak22 == 1
                                        ? "Male"
                                        : epicvoterDetails.ak22 == 2
                                          ? "Female"
                                          : "Others"
                                      : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Area</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ab18 ? epicvoterDetails.ab18 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">District</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ak95 ? epicvoterDetails.ak95 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">State</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ab14 ? epicvoterDetails.ab14 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Assembly Constituency</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ab22 ? epicvoterDetails.ab22 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Assembly Constituency Number</label>
                                  <div class="from-label-value">
                                    {{
                                      epicvoterDetails.ab23 ? epicvoterDetails.ab23 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Parliamentary Constituency</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
                                      epicvoterDetails.ab24 ? epicvoterDetails.ab24 : "N/A"
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="epicvoterDetails == ''">
                          <div class="voter-exit-box-outer mb-3">
                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">No records found.</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="showloadervotermodal">
                        <div class="custom-modal-spinner-loader">
                          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterreport" role="tabpanel" aria-labelledby="manual-entry-tab">
              <div>Using Manual</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation modal start here -->
  <div class="modal-mask" v-if="confirm_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
              <div class="d-none">{{ this.ak1 }}</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" class="btn confirm-modal-no-btn" @click="confirmModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="updateVoterPermission(this.ak1)"
              :disabled="showconfirmloaderbtn">
              <span v-if="!showconfirmloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showconfirmloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation modal end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, numeric, maxLength, minLength } from '@vuelidate/validators';
export default {
  data() {
    return {
      v$: useValidate(),
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      showloader: false,
      errmsg: {},
      votercardnumber: '',
      votermobileno: '',
      voteriderr: "",
      errStatus: false,
      epicdivdisplayflag: 0,
      epicvoterDetails: '',
      votermessage: '',
      showupdateloader: false,
      updatebtnstatus: false,
      showloadervotermodal: false,
      epicdivdisplaydetailflag: false,
      confirm_popup_status: false,
      showconfirmloaderbtn: false,
      invalidVoterMsg: false,
    };
  },
  ApiService: null,
  interval: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.loading = true;
    // this.getcountrycode();
  },
  validations() {
    return {
      votermobileno: {
        required: helpers.withMessage('Please enter Mobile No.', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: helpers.withMessage(
          "Please enter vaild Mobile No.",
          minLength(10)
        ), maxLength: maxLength(10)
      },
      votercardnumber: { required: helpers.withMessage('Please voter Id number', required) },
    };
  },
  watch: {
    votercardnumber(votercardnumber) {
      this.errmsg = "";
      this.validateVoterIdNumber(votercardnumber);
      if (votercardnumber.length == 0) {
        this.voteriderr = "";
        this.errStatus = false;
      }
    },
  },
  methods: {
    redirectaddusingmanualurl() {
      this.$router.push("/voters/addusingmanual");
    },
    redirectaddusingaadharurl() {
      this.$router.push("/voters/addusingaadhar");
    },
    reloadepictab() {
      this.$router.push("/voters/addusingepic");
      this.epicdivdisplayflag = 0;
      this.epicdivdisplaydetailflag = false,
        this.votermobileno = '';
      this.votercardnumber = '';
      this.epicvoterDetails = '';
      this.votermessage = '';
      this.errStatus = false;
      this.voteriderr = '';
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    getcountrycode(e) {
      this.ApiService.getcountrycode(e).then((data) => {
        if (data.status == 200) {
          this.countryCodeList = data.data;
        }
      });
    },
    validateVoterIdNumber(e) {
      this.errStatus = false;
      if (!this.validVoterId(e)) {
        this.voteriderr = "Please enter valid voter id";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.voteriderr = "";
        return true;
      }
    },
    validVoterId: function (e) {
      var re = /([A-Za-z]){3}([0-9]){7}$/;
      return re.test(e);
    },
    getVoterDetail() {
      this.v$.$validate();
      let fields = {};
      fields["ak24"] = this.votermobileno;
      fields["voter_id"] = this.votercardnumber;
      if (!this.v$.$error) {
        this.showloader = true;
        this.epicdivdisplaydetailflag = true;
        this.showloadervotermodal = true;
        this.ApiService.addvoterdetails(fields).then((data) => {
          if (data.success === true) {
            this.showloader = false;
            this.epicvoterDetails = data.data;
            this.votermessage = data.message;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.updatebtnstatus = true;
            this.epicdivdisplayflag = 1;
            this.showloadervotermodal = false;
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            if (data.status === 422) {
              this.invalidVoterMsg = data.message;
            }
            this.showloader = false;
            this.epicvoterDetails = '',
              this.epicdivdisplayflag = 0;
            this.showloadervotermodal = false;
            this.epicdivdisplaydetailflag = false;
          }
        });
      }
    },
    confirmModalOpen(e) {
      this.ak1 = e.ak1;
      this.confirm_popup_status = true;
    },

    confirmModalClose() {
      this.confirm_popup_status = false;
    },
    updateVoterPermission(e) {
      this.showconfirmloaderbtn = true;
      this.ak1 = e;
      this.update = 1;
      this.ak24 = this.votermobileno;
      this.voter_id = this.votercardnumber;
      this.epicdivdisplayflag = 0;
      this.epicdivdisplaydetailflag = true;
      this.showloadervotermodal = true;
      this.ApiService.addvoterdetails({ ak1: this.ak1, ak24: this.ak24, voter_id: this.voter_id, update: this.update }).then((data) => {
        if (data.success === true) {
          this.showconfirmloaderbtn = false;
          this.epicvoterDetails = data.data;
          this.votermessage = data.message;
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.epicdivdisplayflag = 1;
          this.updatebtnstatus = false;
          this.showloadervotermodal = false;
          this.confirm_popup_status = false;
          this.redirecttovoterlisting();
        } else {
          this.showconfirmloaderbtn = false;
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.epicdivdisplayflag = 0;
          this.showloadervotermodal = false;
          this.epicdivdisplaydetailflag = false;
        }
      });
    },
    redirecttovoterlisting() {
      this.$router.push("/voters/myvoters");
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    mobileSearchFormat($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }

        var mobileInput = document.getElementById("searchmobInput");
        if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
            $event.preventDefault();
        }
    },
  },

};
</script>
<style scoped>
.voter-detail-box-outer {
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  border-top: 0;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 522px;
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}

.voter-already-user-status {
  padding: 8px 16px;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  margin-bottom: 15px;
  color: #63957d;
  font-weight: 700;
  font-family: "AcuminPro-SemiBold";
}

.clear-addvoter-btn.btn {
  background: #5266E5 0% 0% no-repeat padding-box;
  border-color: #5266E5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 15px;
  margin-left: 8px;
  font-family: 'AcuminPro-Regular';
  height: 34px;
}
</style>